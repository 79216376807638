import { Controller } from '@hotwired/stimulus'

export default class SelectController extends Controller {
  static targets = ['input']

  static values = {
    filterOptions: Object,
    filteredBy: String,
    initialSelected: String,
  }

  connect() {
    this.filterField.addEventListener('change', this.onFilterChange)
    this.updateForFilter(this.filterField.value)

    if (this.initialSelectedValue) {
      this.inputTarget.value = this.initialSelectedValue
    }
  }

  disconnect() {
    this.filterField.removeEventListener('change', this.onFilterChange)
  }

  onFilterChange = (e) => {
    const filterValue = e.target.value
    this.updateForFilter(filterValue)
  }

  updateForFilter = (filterValue) => {
    const filteredOptions = this.filterOptionsValue[filterValue]

    // Remove the existing options except the first one if it is a blank option
    const existingOptions = this.inputTarget.querySelectorAll('option')
    existingOptions.forEach((option, i) => {
      if (i === 0 && option.value === '') {
        return
      }
      option.remove()
    })

    // Add the new options
    filteredOptions?.forEach((option) => {
      const optionElement = document.createElement('option')
      optionElement.value = option[1]
      optionElement.innerText = option[0]
      this.inputTarget.appendChild(optionElement)
    })
  }

  get filterField() {
    const enclosingForm = this.inputTarget.closest('form')
    return enclosingForm.querySelector(`#${this.filteredByValue}`)
  }
}
